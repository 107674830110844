<template>
  <div v-if="valid_group == false" class="">
    <div class="erorr_page">
      <div class="cont">
        <div class="logo text-center">
          <img
            v-if="base_URL.includes('fehm')"
            style="width: 86%"
            src="/media/fehm_logo.png"
          />
                 <img v-else-if="base_URL.includes('mcit')"  alt="Logo" src="/media/logo.png" />
                <img v-else  alt="Logo" src="/media/Logos/manash.png" />
        </div>
        <p>
          عفوا انت تحاول الوصول الى مجموعه غير موجوده
          ,برجاءالتأكد من الرابط وإعاده المحاولة
        </p>
      </div>
    </div>
  </div>
</template>



<script>
import auth from "../../http/requests/auth";
import { mapGetters } from "vuex";
export default {
  name: "Validte_GroupSlug",
  data() {
    return {
      valid_group: null,
      token: null,
      base_URL: "",
      userData: {},
    };
  },

  methods: {
    ValidteGroupSlug() {
      this.$vs.loading();
      auth
        .check_valid_group({
          slug: this.$route.params.groupslug,
        })
        .then((res) => {
          if (res.data.data) {
            if (this.token) {
              auth
                .user_check_join_group({
                  email: this.userData.email,
                  slug: this.$route.params.groupslug,
                })
                .then((res) => {
                 
                  if (res.data.status == "userInGroup")
                    this.$vs.notify({
                      text: "انت بالفعل عضو فى هذه المجموعة",
                      color: "warning",
                    });
                  else if (res.data.status == true) {
                    this.$vs.notify({
                      text: "تمت إضافتك للمجموعة بنجاح",
                      color: "success",
                    });
                  }
                   this.$router.push(`/register/${this.$route.params.groupslug}`);
                });
            } else {
              this.$router.push(`/register/${this.$route.params.groupslug}`);
              this.valid_group = true;
            }
          } else {
            if (this.token) {
              this.$vs.notify({
                text: "عفوا انت تحاول الوصول الى مجموعه غير موجوده ,برجاءالتأكد من الرابط وإعاده المحاولة",
                color: "danger",
              });
            } else {
              this.valid_group = false;
            }
          }
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
  created() {
    this.base_URL = window.location.origin;
    this.token = localStorage.getItem("id_token");
    this.userData = JSON.parse(localStorage.getItem("User_data"));
    this.ValidteGroupSlug();
  },
};
</script>
<style scoped lang="scss">
.erorr_page {
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .cont {
    width: 500px;
    .logo {
      width: 500px;
      margin-bottom: 25px;
      img {
        width: 100%;
      }
    }
    p {
      font-size: 20px;
    }
  }
}
</style>
